<template>
    <div id="types">
        <div class="navbar">
            <button>分类</button>
            <button @click="screenPopup = true"><i class="iconfont icon-shaixuan2"></i>筛选</button>
        </div>
        <div class="productBox">
            <div class="left">
                <van-sidebar v-model="activeKey" @change="changeSidebar">
                    <van-sidebar-item v-for="(item, index) in types" :key="index" :title="item.name" />
                </van-sidebar>
            </div>
            <div :class="productList.length > 0 ? 'right vanList' : 'right right_bg vanList'">
                <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                :immediate-check="false"
                @load="onLoad"
                class="rightList"
                >
                    <div v-for="(item, index) in productList" :key="index" @click="jump(item.productId)" class="card">
                        <div class="imgBox">
                            <img :src="item.listImg"/>
                        </div>
                        <div>
                            <h4>{{item.productName}}</h4>
                            <p>￥{{item.salePrice}}+{{item.integral}}良豆</p>
                        </div>
                    </div>
                </van-list>
            </div>
        </div>
        <van-popup v-model="screenPopup" position="right" class="screenPopup">
            <div class="content">
                <h4 class="title" v-if="childrenType.length > 0">筛选</h4>
                <ul>
                    <li v-for="(item, index) in childrenType" :key="index" @click="changeType(item.id, index)" :class="{'active': screenOne == index}">{{item.name}}</li>
                </ul>
                <h4 class="title" v-if="secondeLevelType.length > 0">二级</h4>
                <ul>
                    <li v-for="(item, index) in secondeLevelType" :key="index" @click="changeSecondeLevelType(item.id, index)" :class="{'active': screenTwo == index}">{{item.name}}</li>
                </ul>
                <!-- <h4 class="title">价格区间</h4>
                <div class="interval">
                    <input type="number" placeholder="最低价">
                    <span></span>
                    <input type="number" placeholder="最高价">
                </div>
                <h4 class="title">良豆区间</h4>
                <div class="interval">
                    <input type="number" placeholder="最低价">
                    <span></span>
                    <input type="number" placeholder="最高价">
                </div> -->
            </div>

            <div class="footer">
                <button @click="resetScreen">重置</button>
                <button @click="confirmScreen">确认</button>
            </div>
        
        </van-popup>
    </div>
</template>

<script>
import {getCategories} from '../../api/type'
import {getproducts} from '../../api/home'
export default {
    data () {
        return {
            activeKey: 0,
            screenPopup: false,
            appKey: '',
            types: [], //分类
            productData: { //商品列表入参
                appKey: '',
                index: 1,
                pageSize: 20,
                categoryids: [],
                path: '',
                shopLimit: 0
            },
            productList: [], //产品列表
            childrenType: [], //子分类
            secondeLevelType: [], //二级分类
            screenOne: -1, //一级筛选定位
            screenTwo: -1, //二级筛选定位
            loading: false,
            finished: false
        }
    },
    mounted () {
        const {appKey, shoplimit,uid} = this.$route.query;
        this.appKey = appKey;
        this.productData.appKey = appKey;
        shoplimit && (this.productData.shopLimit = parseInt(shoplimit));
        this.getType(uid);
    },
    activated () {
        const scrollTop = this.$route.meta.scrollTop;
        const $content = document.querySelector('.vanList');
        if (scrollTop && $content) $content.scrollTop = scrollTop;
    },
    methods: {
        /* 一级分类选择 */
        changeType (id, index) {
            this.screenOne = index;
            this.productData.categoryids[0] = id;
            this.secondeLevelType = this.childrenType[index].children;
            this.screenTwo = -1;
            this.productData.categoryids.splice(1, 1);
            console.log(this.productData);
        },

        /* 二级分类选择 */
        changeSecondeLevelType (id, index) {
            this.screenTwo = index;
            this.productData.categoryids[1] = id;
            console.log(this.productData);
        },

        /* 重置筛选 */
        resetScreen () {
            this.productData.categoryids = [];
            this.screenOne = -1;
            this.screenTwo = -1;
            this.screenPopup = false;
            this.getProduct(1);
        },

        /* 确认筛选 */
        confirmScreen () {
            this.getProduct(1);
            this.screenPopup = false;
        },

        /* 侧边导航选择 */
        changeSidebar (index) {
            console.log(this.types[index]);
            this.productData.categoryids = [];
            this.productData.path = this.types[index].path;
            this.screenOne = -1;
            this.screenTwo = -1;
            this.loading = false,
            this.finished = false;
            this.productList = [];
            this.getProduct(1);
        },

        /* 获取分类 */
        async getType (uid) {
            let res = await getCategories({uid});
            const {code, data, msg} = res;
            if (code === 1) {
                this.types = data;
                this.productData.path = data[0].path;
                this.childrenType = data[0].children;
                console.log(this.childrenType);
                this.getProduct(1);
                this.finished = false;
            } else {
                this.Toast(msg);
            }
        },

        /* 获取分类对应的商品 */
        async getProduct (num) {
            const {productData, appKey} = this;
            this.productData.appKey = appKey;
            num && (this.productData.index = num);
            let res = await getproducts(productData);
            console.log(res);
            const {code, data, msg} = res;
            if (code === 1) {
                productData.index > 1 ?
                this.productList.push(...data.list) :
                this.productList = data.list;
            } else {
                this.Toast(msg);
            }
            this.loading = false;
            data.list < 20 && (this.finished = true);
        },

        /* 加载更多 */
        onLoad () {
            this.productData.index++;
            this.getProduct();
        },

        /* 页面跳转 */
        jump (productId) {
            const {$router, $route} = this;
            const {appKey, credits, timestamp, sign, uid, integral} = $route.query;
            $router.push({name:'productdetails', query:{appKey, credits, timestamp, sign, uid, productId, integral}});
        },
    }
}
</script>

<style lang="less" src="./index.less">

</style>