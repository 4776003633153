import axios from '../libs/ajaxRequest';

/* 获取产品分类 */
const getCategories = (data) => {
    return axios.request({
        url: `product/category/${data.uid}`,
        method: 'get'
    });
};

export {
    getCategories
}